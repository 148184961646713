.home-deliverydate-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: v.$white;
  height: 54px;
  width: 350px;
  margin: 24px;
  margin-top: 16px;
  box-sizing: border-box;
  padding: 0 16px;
  box-shadow: 0px 2px 10px rgba(v.$black, 0.15);
  &.production-daterange-wrapper {
    width: 365px;
  }
  cursor: pointer;
  .delivery-arrow-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    .upside-down {
      transform: rotate(180deg);
    }
  }
  p {
    color: v.$primary-color;
    font-weight: bold;
    font-size: 20px;
    margin-top: 11px;
    margin-bottom: 10px;
    white-space: nowrap;
  }
  .filter-select {
    max-width: 70%;
    padding: 10px 0px 10px 16px;
    .custom-input-wrapper {
      padding-bottom: 0px;
      padding-top: 0px;
      width: 100%;
      cursor: pointer;
      .custom-input {
        cursor: pointer;
        height: auto;
        padding: 0;
        border: 0;
        background-clip: unset;
        font-size: 16px;
        text-align: right;
      }
    }
  }
}

.card-list-wrapper {
  margin: 0 24px;
  .timeslot-card {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px 4px 7px;
    cursor: pointer;
    .card-header {
      border: 0;
      .svgicon {
        fill: v.$star-icon-color;
        font-size: 22px;
        margin-left: 4px;
        width: 22px;
        height: 22px;
      }
    }
  }
}

.first-order-wrapper{
  display: flex;
  justify-content: center;
  .first-order-style{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    font-size: 13px;
  }
}