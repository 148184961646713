.florists-container {
  text-align: center;
  margin: 10px 0;

  .custom-btn-florists {
    display: inline;
    width: unset;
    height: 36px;
    margin: 4px 2px;
    padding: 0 1.1em;
    background-color: v.$florist-btn-color;
    color: v.$black;

    &.active {
      color: v.$white;
      background-color: v.$primary-color;
    }
  }
}
