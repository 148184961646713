.header-wrapper {
  position: relative;
  -ms-flex-order: -1;
  order: -1;
  width: 100%;
  z-index: 10;
  padding: 5px 15px;
  background-color: v.$white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 66px;
  .header-left-column,
  .header-right-column {
    display: flex;
    align-items: center;
  }
  .header-left-column {
    justify-content: space-between;
    .buttons-column {
      display: flex;
      flex-direction: row;
    }
  }
  .header-right-column {
    justify-content: flex-end;
    flex-grow: 1;
  }
  .custom-btn-header {
    width: 193px;
    height: 40px;
    margin: 0 2px;
    padding: 5px 0;
    box-sizing: border-box;
  }

  .header-center-column {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    .search-form {
      max-width: 300px;
      width: 100%;
      position: relative;
      #search-header-input {
        padding: 6px 20px 6px 40px;
        background-position: left 8px center;
        height: auto;
        font-weight: 400;
        line-height: 30px;
        box-sizing: border-box;
        border: 1px solid v.$grey-100;
        border-radius: 3px;
        font-size: 14px;
        width: 100%;

        &:focus {
          outline-color: v.$primary-color;
        }
      }
      .magnifying-glass {
        color: v.$search-icon-color;
        font-size: 16px;
        position: absolute;
        top: 15px;
        left: 15px;
      }
    }
  }

  .header-center-column {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    .search-form {
      max-width: 300px;
      width: 100%;
      position: relative;
      #search-header-input {
        padding: 6px 20px 6px 40px;
        background-position: left 8px center;
        height: auto;
        font-weight: 400;
        line-height: 30px;
        box-sizing: border-box;
        border: 1px solid v.$grey-100;
        border-radius: 3px;
        font-size: 16px;
        width: 100%;

        &:focus {
          outline-color: v.$primary-color;
        }
      }

      .svgicon {
        color: v.$search-icon-color;
        fill: v.$search-icon-color;
        width: 21px;
        height: 21px;
        position: absolute;
        top: 12px;
        left: 15px;
      }
    }
  }

  .custom-btn-header-icon {
    width: 40px;
    height: 40px;
    margin-top: 0;
    color: v.$primary-color;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.01);
    border-radius: 5px;
    cursor: pointer;
    padding: 8px;
    background-color: v.$white;
    margin-right: 10px;
  }
}

.header-selected-date {
  margin-left: 20px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}

.header-selected-slot {
  margin-left: 10px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}
