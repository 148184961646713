.production-header {
  background-color: v.$white;

  .production-date-container {
    padding: 24px;
    border-bottom: 1px solid v.$grey-100;
    p {
      margin: unset;
    }
  }

  .production-tab-labels {
    margin: 0 24px;
    padding: 24px 0;

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    li {
      flex-grow: 1;
      font-size: 14p;
      font-weight: bold;
      text-align: center;
      color: v.$card-header-text-color;
      width: 100%;
      max-width: calc(100% / 7);
    }
  }
}

.job-container {
  margin-top: 16px;
}

.no-data {
  background-color: v.$white;
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  padding: 20px;
}
