.view-toggle-wrapper {
  display: flex;
  flex-direction: column;

  .view-btns-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85px;
    width: 100%;
    color: v.$grey-100;
    background-color: v.$white;
    user-select: none;
    padding: 0 30px;
    box-sizing: border-box;
    .custom-button {
      &:first-child {
        margin-right: 5px;
      }
      &:nth-child(2) {
        margin-left: 5px;
      }
    }
    .custom-btn-active-view {
      text-transform: capitalize;
      height: 49px;
      width: 50%;
      margin: 0 auto;
      box-shadow: unset;
      border-radius: 6px;
      font-size: 24px;
      font-weight: 500;
      &:hover {
        background-color: darken(v.$primary-color, 5);
      }
      &:active {
        background-color: darken(v.$primary-color, 10);
      }
    }
    .custom-btn-inactive-view {
      background-color: v.$grey-400;
      text-transform: capitalize;
      height: 49px;
      width: 50%;
      margin: 0 auto;
      box-shadow: unset;
      border-radius: 6px;
      font-size: 24px;
      font-weight: 500;
      &:hover {
        background-color: darken(v.$grey-400, 5);
      }
      &:active {
        background-color: darken(v.$grey-400, 10);
      }
    }
  }
}
