.assign-modal {
    min-height: 633px;
    max-width: 70%;
    .modal-content {
        height: 803px;
        margin: 0;
        box-sizing: border-box;
        display: block;
        border: 0;
    }
    .modal-header {
        padding: 24px 32px;
        border-color: v.$grey-400;
        .modal-title {
            margin-top: 0;
            font-size: 24px;
            line-height: 28px;
            font-weight: 700;
            .order-number {
                font-weight: 400;
            }
        }
    }
    .modal-body {
        padding: 0;
        height: calc(100% - 160px);
        overflow: scroll;
        .assign-container {
            display: flex;
            height: 100%;
            .assign-col-6 {
                position: relative;
                flex-basis: 50%;
                ul {
                    margin: 0;
                    min-height: 100%;
                    padding: 15px;
                    .product-card {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        margin-top: 24px;
                        &:first-child {
                            margin-top: 0;
                        }
                        .product-card-img {
                            width: 240px;
                            height: 240px;
                            img {
                                border-radius: 6px;
                                width: 100%;
                            }
                        }
                        .product-card-content {
                            h3 {
                                font-family: 'Arial';
                                font-size: 16px;
                                margin: 11px 0;
                            }
                            .prepType-btn {
                                margin-top: 0;
                                width: fit-content;
                                border-radius: 4px;
                                p {
                                    font-size: 12px;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
                .flw-list {
                    list-style: none;
                    padding: 16px 66px 16px 31px;
                    min-height: 100%;
                    border-inline-start: 1px solid v.$grey-400;
                    .flw-item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        width: 100%;
                        justify-items: center;
                        position: relative;
                        label {
                            width: 100%;
                            margin: 0 21px 0 0;
                            padding: 11px 61px 11px 11px;
                            box-sizing: border-box;
                            background-color: v.$hf-btn-color;
                            color: v.$black;
                            box-shadow: none;
                            border-radius: 6px;
                            font-size: 16px;
                            font-weight: 500;
                            text-transform: capitalize;
                            &.active {
                            color: v.$white;
                            background-color: v.$primary-color;
                            }
                        }
                        .florist-order-count {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            right: 55px;
                            width: 19px;
                            height: 19px;
                            top: 11px;
                            background-color: v.$green;
                            border-radius: 50%;
                            p {
                              margin: 0;
                              color: v.$white;
                              font-size: 14px;
                              font-weight: 500;
                              line-height: 18px;
                            }
                        }
                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 24px;
                            width: 24px;
                            margin: 0;
                            z-index: 2;
                        }
                        .custom-radio {
                            height: 24px;
                            width: 24px;
                            border: 1px solid v.$primary-color;
                            background-color: v.$white;
                            border-radius: 50%;
                            box-sizing: border-box;
                            cursor: pointer;
                            position: relative;
                            &:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }
                        }
                        .custom-radio-container{
                            &:hover {
                                .custom-radio {
                                    background-color: v.$primary-color;
                                    &::after {
                                        left: 6px;
                                        top: 6px;
                                        width: 10px;
                                        height: 10px;
                                        border: 5px solid v.$white;
                                        border-radius: 50%;
                                        display: block;
                                        box-sizing: border-box;
                                    }
                                }
                            }
                            input:checked + .custom-radio {
                                background-color: v.$primary-color;
                            }
                            input:checked + .custom-radio:after {
                                left: 6px;
                                top: 6px;
                                width: 10px;
                                height: 10px;
                                border: 5px solid v.$white;
                                border-radius: 50%;
                                display: block;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
            }
        }
    }
    .modal-footer {
        padding: 0;
        .flex-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .fixed-footer {
                width: 50%;
                background-color: v.$white;
                padding: 16px 24px;
                box-shadow: 0px 2px 16px rgba(v.$black, 0.16);
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                &:nth-child(2) {
                    padding: 16px 12px;
                }
                .custom-button {
                    padding: 16px;
                    border-radius: 6px;
                    cursor: pointer;
                    border: none;
                    font-size: 16px;
                    margin-top: 0;
                    height: 51px;
                    text-transform: none;
                    box-shadow: none;
                }
                .custom-btn-assignToMe {
                    width: 268px;
                    box-shadow: unset;
                    border-radius: 6px;
                    font-size: 16px;
                    font-weight: 500;
                    &:hover {
                    background-color: darken(v.$primary-color, 5);
                    }
                    &:active {
                    background-color: darken(v.$primary-color, 10);
                    }
                    &:disabled {
                        cursor: default;
                        opacity: 0.5;
                        pointer-events: none;
                        background-color: v.$grey-300;
                    }
            
                }
                .custom-btn-assign {
                    width: 138px;
                    box-shadow: unset;
                    border-radius: 6px;
                    font-size: 16px;
                    font-weight: 500;
                    &:hover {
                    background-color: darken(v.$primary-color, 5);
                    }
                    &:active {
                    background-color: darken(v.$primary-color, 10);
                    }
                    &:disabled {
                        cursor: default;
                        opacity: 0.5;
                        pointer-events: none;
                        background-color: v.$grey-300;
                    }
            
                }
                .custom-btn-cancel {
                    width: 138px;
                    color: v.$primary-color;
                    background-color: v.$white;
                    border: 1px solid v.$primary-color;
                    border-radius: 6px;
                    font-size: 16px;
                    font-weight: 500;
                    &:hover {
                        background-color: v.$primary-color;
                        color: v.$white;
                    }
    
                    &:active {
                        background-color: darken(v.$primary-color, 10);
                    }
                }
            
            }
        }
    }
}
