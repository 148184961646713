.single-filter-date {
  display: flex;
  align-items: center;
}

.single-date-picker-modal {
  position: absolute;
  top: 75px;
  z-index: 3000;
  border: 1px solid v.$white;
  background-color: v.$white;
}