.upload-img-modal-wrapper {
  position: relative;
}

.upload-img-modal {
  height: 100%;
  width: 100%;
  max-width: 100%;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
  position:  absolute;
  .modal-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: block;
    .modal-header {
      padding: 0;
      .modal-title {
        margin: 0;
        .buttons-column {
          align-items: center;
          justify-content: center;
          .img-item-name {
            color: v.$card-header-text-color;
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
          }
        }
      }
    }
    .modal-body {
      width: 100%;
      height: calc(100% - 66px);
      box-sizing: border-box;
      display: block;
      .video-canvas {
        width: 100%;
        height: 100%;
      }
      .draw-canvas {
        display: none;
      }
      .capture-canvas {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .img-fixed-footer {
        height: 100px;
        background-color: transparent;
        width: calc(100% - 32px);
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .capture-btn {
          position: relative;
          cursor: pointer;
          .big-white-circle {
            width: 72px;
            height: 72px;
            background-color: v.$white;
            position: fixed;
            bottom: 28px;
            right: 50%;
            border-radius: 50%;
          }
          .black-ring {
            width: 64px;
            height: 64px;
            background-color: v.$black;
            position: fixed;
            bottom: 32px;
            right: calc(50% + 4px);
            border-radius: 50%;
          }
          .small-white-circle {
            width: 60px;
            height: 60px;
            background-color: v.$white;
            position: fixed;
            bottom: 34px;
            right: calc(50% + 6px);
            border-radius: 50%;
          }
        }
        .buttons-column {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .custom-button {
            width: 162px;
            border-radius: 6px;
            font-family: Gilroy;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: 1px solid v.$primary-color;
          }
          .custom-btn-retake-image {
            background-color: v.$white;
            color: v.$primary-color;
          }
          .custom-btn-upload-image {
            background-color: v.$primary-color;
            color: v.$white;
          }
        }
      }
    }
  }
}

.camera-hide {
  display: none;
}

.camera-show {
  display: block;
}