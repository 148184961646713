.productDetails-left-col {
  width: 33.333%;
  padding: 5px;
  box-sizing: border-box;
}

.productDetails-right-col {
  width: 66.666%;
  padding: 5px;
  box-sizing: border-box;
}