.reasons-modal-wrapper {

}

.reasons-modal-parent {
  overflow-y: scroll;
  .reasons-modal {
    .reasons-warning-msg {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 5px;
      }
      p {
        color: v.$warning-color;
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        margin-top: 4px;
      }
    }
    .modal-content {
      background-color: v.$grey-500;
      box-sizing: border-box;
      .modal-body {
        padding: 16px 0 0 0;
        .reasons-modal-body {
          height: 550px;
          overflow-y: scroll;
          position: relative;
          box-sizing: border-box;
          padding: 0 16px;
          .reason-main-title {
            color: v.$black-300;
            font-family: Gilroy;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .reason-card {
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 22px;
            border-radius: 16px;
            background: v.$white;
            box-sizing: border-box;
            .reason-item-div {
              display: flex;
              padding: 10px;
              align-items: flex-start;
              gap: 9px;
              background: v.$tab-background;
              width: 100%;
              box-sizing: border-box;
              p {
                margin: 0;
              }
              table {
                width: 100%;
                td {
                  color: v.$card-header-text-color;
                  text-align: center;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  .reason-item-image {
                    width: 44px;
                    height: 44px;
                    box-shadow: 0px 4px 40px 0px v.$grey, 0px 4px 8px 0px rgba(v.$image-shadow-color, 0.051), 0px 0px 0.5px 0px rgba(v.$image-shadow-color, 0.32);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                  }
                }
              }
            }
            ul {
              padding: 0;
              margin: 0;
            }
            .reason-list {
              list-style: none;
              padding: 0;
              .reason-item {
                display: flex;
                align-items: center;
                width: 100%;
                justify-items: center;
                color: v.$card-header-text-color;
                font-size: 20px;
                box-sizing: border-box;
                &:last-child{
                  border-bottom: none;
                }
                label {
                  color: v.$card-header-text-color;
                  font-family: Gilroy;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 40px;
                  padding: 10px;
                  width: -webkit-fill-available;
                  margin-inline-end: 20px;
                }
                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 24px;
                  width: 24px;
                  margin: 0;
                  z-index: 2;
                }
                .custom-radio {
                  height: 24px;
                  width: 24px;
                  border: 1px solid v.$primary-color;
                  background-color: v.$white;
                  border-radius: 50%;
                  box-sizing: border-box;
                  cursor: pointer;
                  position: relative;
                  &:after {
                    content: "";
                    position: absolute;
                    display: none;
                  }
                }
                .custom-radio-container{
                  &:hover {
                    .custom-radio {
                      background-color: v.$primary-color;
                      &::after {
                        left: 6px;
                        top: 6px;
                        width: 10px;
                        height: 10px;
                        border: 5px solid v.$white;
                        border-radius: 50%;
                        display: block;
                        box-sizing: border-box;
                      }
                    }
                  }
                  input:checked + .custom-radio {
                    background-color: v.$primary-color;
                  }
                  input:checked + .custom-radio:after {
                    left: 6px;
                    top: 6px;
                    width: 10px;
                    height: 10px;
                    border: 5px solid v.$white;
                    border-radius: 50%;
                    display: block;
                    box-sizing: border-box;
                  }
                }
              }
              .other-input {
                border-radius: 3px;
                border: 0.5px solid v.$grey-700;
                background: v.$white;
                padding: 10px;
                box-sizing: border-box;
                width: 100%;
                color: v.$card-header-text-color;
                font-family: Gilroy;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                &:read-only {
                  background-color: v.$grey-200;
                }
              }
            }
          }
        }
        .reason-modal-footer {
          bottom: 1px;
          left: 1px;
          width: calc(100% - 2px);
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 16px;
          padding: 16px 0;
          box-sizing: border-box;
          background-color: v.$white;
          border-bottom-left-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem;
          box-shadow: 0px 2px 16px 0px rgba(v.$black, 0.16);
          .custom-button {
            width: 162px;
            border-radius: 6px;
            font-family: Gilroy;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: 1px solid v.$primary-color;
            margin-top: 0;
            box-shadow: none;
          }
          .custom-btn-cancel-reason {
            background-color: v.$white;
            color: v.$primary-color;
          }
          .custom-btn-proceed-reason {
            background-color: v.$primary-color;
            color: v.$white;
          }
        }
      }
    }
  }
}