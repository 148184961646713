.card-title-text {
  color: v.$primary-color;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
}

.card-title-value {
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
  color: v.$card-body-text-color;
}

.card-col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.orderDetails-card {
  margin: 24px;
  margin-bottom: 16px;
  &.no-deviation {
    margin: 10px;
    margin-bottom: 10px;
  }
  .card-body {
    padding: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .order-slot-col {
      @extend .card-col;
      .delivery-date {
        @extend .card-title-text;
      }
      .delivery-slot {
        @extend .card-title-value;
        white-space: nowrap;
      }
    }
    .order-id-col {
      @extend .card-col;
      .order-id-text {
        @extend .card-title-text;
      }
      .order-id-value {
        @extend .card-title-value;
        white-space: nowrap;
      }
      .nw-order {
        background-color: v.$dark-blue;
        color: v.$white;
        display: inline-block;
        padding: 2px 6px;
        text-align: center;
        font-size: 14px;
        align-self: center;
        margin-top: 3px;
        border-radius: 3px;
        align-self: baseline;
      }
      .svgicon {
        fill: v.$star-icon-color;
        font-size: 27px;
        margin-left: 14px;
        width: 27px;
        height: 27px;
      }
    }
    .delivery-zone-col {
      @extend .card-col;
      .zone-text {
        @extend .card-title-text;
      }
      .zone-value { 
        @extend .card-title-value;
      }
    }
    .order-notes-col {
      @extend .card-col;
      .notes-text {
        @extend .card-title-text;
      }
      .notes-value { 
        @extend .card-title-value;
        color: v.$note-red-color;
      }
    }
    .assign-florist-col {
      @extend .card-col;
      justify-content: center;
      .florist-name {
        @extend .card-title-value;
      }
      .florist-status {
        @extend .card-title-value;
        color: v.$note-red-color;
      }
    }
    .order-status-col {
      @extend .card-col;
      text-align: center;
      &.order-status-col-btns {
        flex-direction: row;
        gap: 12px;
        justify-content: center;
      }
      .custom-btn-status {
        align-self: center;
      }
    }

    .talabat-order-tag{
      margin-top: 0;
      width: fit-content;
      border-radius: 4px;
      background-color: v.$star-icon-color;
      padding: 3px;
      p {
          font-size: 12px;
          margin: 0;
          color: v.$white;

      }
    }
  }
}

.qc-images-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  .qc-attachments{
    display: flex;
    .qc-img{
      width: 55px;
      margin-right: 4px;
      cursor: pointer;
    }
  }
}
