.red {
  color: v.$red;
}

.orderWrapper {
  padding: 5px;
  margin-top: 10px;
  overflow-y: scroll;

  .orderCard {
    margin: 10px;
    margin-top: 0;
    .card-body {
      cursor: pointer;
      .card-body-content {
        cursor: pointer;
        color: v.$card-body-text-color;

        .order-list {
          display: flex;
        }

        .cart-list {
          display: flex;
          justify-content: left;
          align-items: center;
          margin-bottom: 15px;
          padding-top: 30px;

          h6 {
            text-decoration: underline;
            font-weight: bold;
            cursor: pointer;
            font-size: 14px;
          }

          img {
            width: 80px;
            margin-right: 20px;
          }
        }
      }
    }
  }

  .orderHeader {
    display: flex;
    align-items: center;
    border-bottom: 1px v.$order-header-color solid;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
  }
}

.assign-florist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: v.$card-body-text-color;
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 1px;
  padding-inline-end: 5px;
  padding-inline-start: 5px;
  position: relative;

  h6 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    line-height: 1.2;
    font-size: 16px;
  }
  &.zone-florist {
    h6 {
      font-size: 14px;
      font-weight: 400;
    }
  }
  &.zone-order {
    h6 {
      font-weight: 400;
    }
  }
  .product-img {
    width: 80px;
    margin-right: 20px;
  }
  .sku {
    @extend .red;
    margin-left: 10px;
  }
  .product-name {
    margin-left: 10px;
  }
  .order {
    margin-left: 10px;
    font-size: 16px;
  }
  .item {
    @extend .red;
  }
  .order-id {
    @extend .red;
    cursor: pointer;
    font-size: 16px;
  }
  .star-icon {
    fill: v.$star-icon-color;
    font-size: 22px;
    margin-left: 18px;
    width: 20px;
  }
  .florist-name {
    text-align: center;
  }
  .prep-status {
    text-align: center;
    @extend .red;
  }
  .no-orders-title {
    text-align: center;
  }

  .order-priority {
    font-size: 23px;
    @extend .red;
  }
}

.custom-btn-status {
  align-self: flex-start;
  height: unset;
  width: unset;
  margin-top: unset;
  padding: 10px 15px;
}

.hide {
  display: none;
}

.statusBtn {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex-basis: 0;
  flex-grow: 1;
  font-size: 14px;
  line-height: 21px;
  max-width: 100%;
  min-height: 1px;
  overflow-wrap: break-word;
  padding: 5px;
  position: relative;
  user-select: none;
  .custom-button {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}