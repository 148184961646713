.sort-group-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: v.$white;
  border-top: 1px solid v.$background-color;
  border-bottom: 1px solid v.$background-color;
  padding: 16px 30px;
  .slot-date-wrapper {
    h5 {
      margin: 0;
      text-align: left;
      font-size: 20px;
      font-weight: 700;
      color: v.$black;
    }
  }
  .selects-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .filter-select .control-pane {
      border: 0;
      box-shadow: 0px 2px 10px rgba(v.$black, 0.15);
      border-radius: 6px;
    }
    .header-groupBy-wrapper {
      margin-right: 10px;
    }
    .header-groupBy-wrapper, .header-sortBy-wrapper {
      width: 207px;
    }
  }
}