.notification-btn-wrapper {
  position: relative !important;
  .notification-badge {
    position: absolute;
    bottom: 0;
    left: -8px;
    width: 18px;
    height: 18px;
    background-color: red;
    border-radius: 10px;
    // margin-right: -10px;
    cursor: pointer;
    color: #fff;
    // margin-top: 6px;
    padding: 0;
    display: block;
    visibility: visible;
    min-width: unset;
    white-space: unset;
    contain: unset;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    overflow-wrap: break-word;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-self: center;
    }
  }
}

.notification-modal {
  position: absolute !important;
  top: 60px;
  right: 95px;
  margin-top: 0 !important;
  width: 250px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  .modal-header {
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 45px;
    display: flex;
    align-items: center;
    h5 {
      margin-top: 0;
      color: v.$primary-color;
      font-size: 14px;
      font-weight: 700;
    }
  }
  .modal-body {
    .notification-text {
      font-size: 13px;
      .notification-img {
        width: 25px;
        height: 25px;
        &.hide {
          display: none;
        }
      }
    }
  }
}