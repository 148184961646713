.notification-btn-wrapper {
  position: relative !important;
  .notification-badge {
    position: absolute;
    bottom: 0;
    left: -8px;
    width: 18px;
    height: 18px;
    background-color: red;
    border-radius: 10px;
    // margin-right: -10px;
    cursor: pointer;
    color: #fff;
    // margin-top: 6px;
    padding: 0;
    display: block;
    visibility: visible;
    min-width: unset;
    white-space: unset;
    contain: unset;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    overflow-wrap: break-word;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-self: center;
    }
  }
}

.notification-modal {
  position: absolute !important;
  top: 60px;
  right: 40px;
  margin-top: 0 !important;
  width: 460px !important;
  max-height: 80%;
  border-radius: 10px;
  box-shadow: rgba(v.$black, 0.2) 0px 5px 5px -3px, rgba(v.$black, 0.14) 0px 8px 10px 1px, rgba(v.$black, 0.12) 0px 3px 14px 2px;
  .modal-header {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 55px;
    display: flex;
    align-items: center;
    h5 {
      margin-top: 0;
      color: v.$black;
      font-size: 14px;
      font-weight: 700;
    }
  }
  .read-notfication{
    position: absolute;
    display: flex;
    right: 10px;
    top: 6px;
    .read-notification-btn{
      width: auto;
      height: auto;
      padding: 8px;
      margin: 6px;
      font-size: 12px;
    }

    .all-unread-style, .read-style{
      background-color: transparent;
      color: v.$search-icon-color
    }

    .all-read-style, .unread-style{
      background-color: v.$primary-color;
      columns: v.$white;
    }
  }
  .modal-body {
    max-height: 500px;
    overflow-y: scroll;
    .notification-text {
      font-size: 13px;
      padding: 10px 20px;
      border-radius: 10px;
      margin-bottom: 5px;
      cursor: pointer;
      .content-style{
        display: flex;
        color: v.$light-gray-text;
        justify-content: space-between;
        margin: 7px 0;
        div{
          width: 90%;
          align-self: center;
        }
        p{
          text-align: end;
          margin: 0;
        }
      }
      a{
        color: v.$primary-color;
        font-weight: bold;
        font-size: 14px;
        text-decoration: none;
      }
      .submit-notification{
        width: auto;
        height: auto;
        padding: 6px;
        margin: 10px 0;
        font-size: 12px;
      }
    }
    .read-bg-color{
      background-color: v.$notification-bg-color;
    }
  }
}