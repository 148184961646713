.florists-container {
  margin: 0;
  padding: 10px 30px;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: v.$white;
  .florist-filter-wrapper {
    display: flex;
    position: relative;
  }
  .custom-btn-florists {
    display: inline;
    width: unset;
    height: 41px;
    margin: 5px;
    padding: 11px 61px 11px 11px;
    background-color: v.$hf-btn-color;
    color: v.$black;
    box-shadow: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    &.active {
      color: v.$white;
      background-color: v.$primary-color;
    }
  }
  .florist-order-count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    width: 19px;
    height: 19px;
    top: 16px;
    background-color: v.$green;
    border-radius: 50%;
    p {
      margin: 0;
      color: v.$white;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }
}
