.productDetails-left-col {
  width: 33.333%;
  padding: 0px;
  box-sizing: border-box;
  .slide-container {
    margin-top: 21px;
    margin-left: 14px;
    > div {
      border-radius: 6px;
      overflow: hidden;
    }
  }
  .productDetails-left-details {
    margin-left: 14px;
    p {
      color: v.$black;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      line-height: 27px;
      margin: 0;
    }
  }
  .custom-btn-basket {
    margin: 13px 0px 10px 14px;
    width: calc(100% - 14px);
    box-sizing: border-box;
    background-color: v.$white;
    color: v.$primary-color;
    border: 1px solid v.$primary-color;
    border-radius: 6px;
    box-shadow: none;
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;
    height: 45px;
    padding: 10px;
  }
}

.productDetails-right-col {
  width: 66.666%;
  padding: 0px;
  box-sizing: border-box;
}

.productDetails-left-header, .productDetails-right-header {
  height: 50px;
  background-color: v.$grey-500;
  border: 1px solid v.$grey-600;
  border-radius: 6px 6px 0px 0px;
}

.productDetails-left-header {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.productDetails-right-header {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 14px;
  .table {
    border: 0;
    width: 100%;
    tr {
      height: 44px;
      width: 100%;
      th {
        color: v.$production-text-color;
        text-align: center;
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        word-wrap: break-word;
        &:nth-child(1) {
          width: 18%;
        }
        &:nth-child(2) {
          width: 18%;
        }
        &:nth-child(3) {
          width: 14%;
        }
        &:nth-child(4) {
          width: 8%;
        }
        &:nth-child(5), &:nth-child(6), &:nth-child(7) {
          width: 14%;
        }
      }
    }
  }
}