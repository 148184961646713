.product-details-table {
  width: calc(100% - 28px);
  text-align: center;
  border-radius: 6px;
  border: 1px solid v.$grey-700;
  box-sizing: border-box;
  margin: 21px 14px 10px 14px;
  .table-container {
    height: 435px;
    overflow-y: scroll;
    .table {
      border: 0;
      border-radius: 6px;
      overflow: hidden;
      width: 100%;
      -webkit-border-horizontal-spacing: 0px;
      -webkit-border-vertical-spacing: 0px;
      .inventoryTable-image {
        width: 72px;
        height: 72px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: 0px 4px 40px 0px v.$grey, 0px 4px 8px 0px rgba(v.$image-shadow-color, 0.051), 0px 0px 0.5px 0px rgba(v.$image-shadow-color, 0.32);
      }
      tr{
        padding: 10px;
        height: 94px;
        &:nth-child(2n + 1) {
          background-color: v.$grey-500;
        }
      }
      td {
        color: v.$card-header-text-color;
        text-align: center;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        word-wrap: anywhere;
        > * {
          margin: 0 auto;
        }
        &:nth-child(1) {
          width: 18%;
        }
        &:nth-child(2) {
          width: 18%;
        }
        &:nth-child(3) {
          width: 14%;
        }
        &:nth-child(4) {
          width: 8%;
        }
        &:nth-child(5), &:nth-child(6), &:nth-child(7) {
          width: 14%;
        }
        .qty-input {
          width: 90%;
          border-radius: 3px;
          border: 0.5px solid v.$grey-700;
          background: v.$white;
          padding: 10px;
          box-sizing: border-box;
          &:read-only {
            background-color: v.$grey-200;
          }
        }
        .camera-functionality-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 44px;
          .camera-dashed-container {
            border: 1px dashed v.$grey-800;
            background: v.$blue-100;
            height: 42px;
            width: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.disable-open-camera {
              cursor: not-allowed;
            }
          }
          .camera-small-preview {
            height: 42px;
            width: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          .camera-icons-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 6px;
            .camera-add-more, .camera-delete-all {
              width: 20px;
              height: 20px;
              border-radius: 3px;
              background: v.$blue-200;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &.disable-add-more {
                cursor: not-allowed;
                background-color: v.$grey-800;
              }
            }
          }
        }
      }
    }
  }
  .submit-deviation-btn {
    margin-top: 0;
    width: 50%;
    height: 45px;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 10px 10px auto;
    color: v.$white;
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.orange-border {
  border: 1px solid orange !important;
}