.basket-modal-wrapper {
  position: relative;
}


.basket-modal {
  height: 70%;
  width: 100%;
  max-width: 100%;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
  position:  absolute;
  .modal-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: block;
    border-radius: 20px 20px 0 0;
    border: 0;
    overflow: auto;
    .modal-body {
      padding: 0;
      .table {
        border: 0;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        th {
          box-sizing: border-box;
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          color: v.$black;
          width: calc(100%/4);
          &:first-child {
            padding: 25px;
          }
        }
        td {
          font-size: 16px;
          line-height: 21px;
          color: v.$black;
          width: calc(100%/4);
          .basket-item-img {
            width: 80px;
          }
        }
        tbody {
          tr {
            text-align: center;
          }
        }
      }
      .empty-basket-text {
        color: v.$red;
        text-align: center;
        margin: 0;
        padding: 16px 0;
      }
    }
  }
}