.product-details-table-no-deviation {
  width: 100%;
  height: 500px;
  text-align: center;
  .table {
    border: 0;
    width: 100%;
    .inventoryTable-image {
      width: 70px;
    }
    th {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      color: v.$card-body-text-color;
      width: calc(100%/4);
    }
    td {
      font-size: 14px;
      line-height: 21px;
      color: v.$card-body-text-color;
      width: calc(100%/4);
    }
  }
}