.rdrDefinedRangesWrapper, .rdrDateRangeWrapper {
  padding-bottom: 40px;
}

#date-range-picker {
  position: absolute;
  top: 75px;
  z-index: 3000;
  border: 1px solid v.$grey;
  &.right{
    right: 15px;
  }
}

.custom-btn-daterange-apply {
  position: absolute;
  padding: 6px 16px;
  font-size: 13px;
  cursor: pointer;
  background-color: v.$primary-color;
  line-height: 1;
  width: 80px;
  height: 30px;
}