.cart-col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.orderDetails-cart-wrapper {
  background-color: transparent;
  display: block;
  overflow-y: scroll;
  flex: 1;
  width: 100%;
  padding: 24px;
  padding-top: 0;
  height: 720px;
  box-sizing: border-box;
  contain: size style;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
  &.no-deviation {
    background-color: v.$white;
  }
  .orderDetails-cartItem-wrapper {
    margin: 0px;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0px 2px 10px 0px rgba(v.$black, 0.15);
    margin-bottom: 16px;
    &.no-deviation {
      padding: 5px;
      box-shadow: none;
      margin-bottom: 0;
    }
    .cartDetails-card {
      margin: 0px;
      padding: 0px;
      display: block;
      flex: 1;
      box-sizing: border-box;
      &.no-deviation {
        margin: 10px;
        padding: 15px;
        overflow: scroll;
      }
      .card-header {
        border: 0;
        padding: 0;
        .card-header-wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .cartDetails-div {
            @extend .cart-col;
            .cartDetails-title {
              color: v.$primary-color;
              font-size: 16px;
              margin-top: 16px;
              margin-bottom: 0px;
              font-weight: bold;
              line-height: 1.2;
            }
            .cartDetails-qty {
              @extend .cartDetails-title;
              color: v.$note-red-color;
              margin-bottom: 10px;
            }
            .custom-btn-basket {
              max-width: 210px;
              font-weight: 500;
              padding: 0 15.4px;
              height: 36px;
              margin-top: 0;
            }
          }
        }
      }
      .card-body {
        display: flex;
        box-sizing: border-box;
        padding: 0;
        &.no-deviation {
          padding: 0 16px 13px;
          box-sizing: unset;
        }
      }
      .card-footer {
        border: 0;
      }
    }
  }
}