.custom-input-wrapper {
  padding-bottom: 5px;
  padding-top: 5px;
  .custom-input {
    display: block;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    line-height: 1.5;
    color: v.$black;
    background-color: v.$white;
    background-clip: padding-box;
    border: 1px solid v.$input-border-color;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}