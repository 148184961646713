.job-card {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 100px;
  margin-bottom: 16px;

  .card-column {
    flex-grow: 1;
    width: 100%;
    max-width: calc(100% / 7);
    p {
      font-size: 16px;
      font-weight: 400;
      color: v.$production-text-color;
    }
  }

  .product-img {
    width: 69px;
    height: 69px;
    margin: 0 auto;
    border-radius: 6px;
    cursor: pointer;
  }

  .plus-minus-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    width: 85%;
    height: 46px;
    max-width: 115px;
    margin: 0 auto;
    border: 3px solid v.$primary-color;
    border-radius: 6px;
    overflow: hidden;

    input {
      text-align: center;
      border: none;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      color: v.$production-text-color;
      height: 40px;
      &:focus-visible {
        outline: none;
      }
    }

    .custom-btn-minus,
    .custom-btn-plus {
      font-size: 16px;
      color: v.$black;
      background-color: v.$white;
      margin: 0;
      box-shadow: unset;
      box-sizing: border-box;
      height: 40px;
      padding: 6px;
      line-height: 40px;
      &:hover {
        background-color: v.$light-color;
      }

      &:active {
        background-color: v.$grey-200;
      }
    }
  }

  .custom-btn-assign {
    text-transform: capitalize;
    height: 46px;
    width: 85%;
    max-width: 150px;
    margin: 0 auto;
    box-shadow: unset;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      background-color: darken(v.$primary-color, 5);
    }
    &:active {
      background-color: darken(v.$primary-color, 10);
    }
  }

  .card-column.caret {
    margin-left: auto;
  }
}

.custom-btn-rightCaret {
  height: auto;
  width: auto;
  margin: 0 auto;
  background-color: unset;
  box-shadow: unset;
}

.right-caret {
  width: 13px;
  height: 24px;
  cursor: pointer;

  &:active {
    transform: scale(1.1);
  }
}
