.quality-check-wrapper{
  padding: 10px ;
  margin: 10px 20px;
  .card-body{
    display: flex;
    flex-direction: column;
    height: 60vh;
    justify-content: space-between;
    .page-header{
      font-weight: bold;
    }
    .page-desc{
      font-size: 15px !important;
      margin-bottom: 20px;
    }
    .upload-instr{
      font-size: 14px !important;
      font-weight: bold;
      margin: 7px 0 !important;
    }

  .reload-img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    cursor: pointer;
    .camera-dashed-container {
      position: relative;
      width: 100%;
      background-repeat: no-repeat; 
      background-position: center; 
      background-origin: center; 
      background-size: cover; 
      height: 100%;
      border: 1px solid var(--grey-100);
    }
    
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); 
      z-index: 1; 
    }
    
    .reload-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; 
      color: v.$primary-color
    }
  }
    .camera-functionality-container {
      justify-content: center;
      width: 100px;
      height: 100px;
      .camera-dashed-container {
        border: 1px dashed v.$grey-100 ;
        background: v.$tab-background;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
          &.disable-open-camera {
                cursor: not-allowed;
                border: 1px dashed v.$danger-color ;
          }
        .take-photo{
          font-size: 14px;
          font-weight: bold;
            color: v.$primary-color;
        }   
      }
    }
    .custom-btn-success{
      width: auto !important;
      height: 35px !important;
      font-size: 15px !important;
      padding: 0px 10px;
      align-self: end;
      background-color: v.$success-color;
    }
    .uploaded-images-wrapper{
      display: flex;
      align-items: center;   
      margin-bottom: 20px;
      .camera-small-preview {
        position: relative;
        margin-right: 10px;
        height: 130px;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        .delete-icon{
          position: absolute;
          top: 8px;
          right: -8px;
        }
      }
    }
  }
  span{
    color: v.$card-body-text-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

