.confirmation-modal-container {
  width: 375px;

  .modal-content {
    height: 208px;
  }

  .confirmation-modal {
    padding: 0;

    p {
      font-size: 20px;
      font-weight: 500;
      padding: 0 16px;
    }
  }

  .confirmation-btns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 83px;
    box-shadow: 0px 2px 16px rgba(v.$black, 0.16);
    padding: 0;

    * {
      font-size: 16px;
      text-transform: capitalize;
      width: 162px;
      margin-top: 0;
      box-shadow: unset;
    }

    .custom-btn-cancel {
      color: v.$primary-color;
      background-color: v.$white;
      border: 1px solid v.$primary-color;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
      &:hover {
        background-color: v.$primary-color;
        color: v.$white;
      }

      &:active {
        background-color: darken(v.$primary-color, 10);
      }
    }

    .custom-btn-proceed {
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
      &:hover {
        background-color: darken(v.$primary-color, 5);
      }

      &:active {
        background-color: darken(v.$primary-color, 10);
      }
    }
  }
}
