.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  width: 100%;
  height: 49px;
  background-color: v.$primary-color;
  color: v.$white;
  font-family: "Gilroy",sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.84px;
  text-align: center;
  padding: 3px 6px;
  cursor: pointer;
  border: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-top: 24px;
  &.secondary {
    background-color: v.$secondary-color;
  }

  &.success {
    background-color: v.$success-color;
  }

  &.danger {
    background-color: v.$danger-color;
  }
  &.hidden {
    display: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
    background-color: v.$order-header-color;
  }
}
