@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Medium'), local('Gilroy-Medium'),
      url('../fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
      url('../fonts/gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Bold'), local('Gilroy-Bold'),
      url('../fonts/gilroy/Gilroy-Bold.woff2') format('woff2'),
      url('../fonts/gilroy/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Regular'), local('Gilroy-Regular'),
      url('../fonts/gilroy/Gilroy-Regular.woff2') format('woff2'),
      url('../fonts/gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, #root, .hf-App, .page-wrapper, .page-body-wrapper {
  width: 100%;
  box-sizing: border-box;
}

body {
  background-color: v.$background-color;
  font-family: "Gilroy", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  margin: 0;
  margin-top: 0;
  width: 100%;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6{
  margin-top: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
}

.form-group {
  position: relative;
}

.svgicon {
  fill: v.$primary-color;
  stroke: currentColor;
  display: block;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  .svgicon-stroke-width {
    stroke-width: 32px;
  }
  .svgicon-fill-none {
    fill: none;
  }
  path.svgicon-fill-none.svgicon-stroke-width {
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
}