.not-done-modal {
    max-width: 50%;
    left: 0;
    margin-bottom: 0;
    position: relative;
    margin: 0 auto;
    .modal-content {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: block;
        border: 0;
        top: 24px;
        overflow: hidden;
        .modal-header {
            padding: 24px 18px;
            border: none;
            .modal-title {
                margin-top: 0;
                font-size: 24px;
                font-weight: 700;
                .order-number {
                    font-weight: 100;
                }
            }
        }
        .modal-body {
            padding: 0;
            .not-done-container {
                ul {
                    padding: 0;
                    margin: 0;
                }
                .reason-list {
                    list-style: none;
                    padding: 0;
                    .reason-item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-items: center;
                        padding: 32px 20px;
                        border-bottom: 1px solid v.$grey-300;
                        color: v.$card-header-text-color;
                        font-size: 20px;
                        box-sizing: border-box;
                        &:last-child{
                            border-bottom: none;
                        }
                        label {
                            color: v.$black;
                            font-size: 20px;
                            font-weight: 500;
                            padding: 10px;
                            border-radius: 6px;
                            width: -webkit-fill-available;
                            overflow: hidden;
                            margin-inline-end: 20px;
                        }
                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 24px;
                            width: 24px;
                            margin: 0;
                            z-index: 2;
                        }
                        .custom-radio {
                            height: 24px;
                            width: 24px;
                            border: 1px solid v.$primary-color;
                            background-color: v.$white;
                            border-radius: 50%;
                            box-sizing: border-box;
                            cursor: pointer;
                            position: relative;
                            &:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }
                        }
                        .custom-radio-container{
                            &:hover {
                                .custom-radio {
                                    background-color: v.$primary-color;
                                    &::after {
                                        left: 6px;
                                        top: 6px;
                                        width: 10px;
                                        height: 10px;
                                        border: 5px solid v.$white;
                                        border-radius: 50%;
                                        display: block;
                                        box-sizing: border-box;
                                    }
                                }
                            }
                            input:checked + .custom-radio {
                                background-color: v.$primary-color;
                            }
                            input:checked + .custom-radio:after {
                                left: 6px;
                                top: 6px;
                                width: 10px;
                                height: 10px;
                                border: 5px solid v.$white;
                                border-radius: 50%;
                                display: block;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
            }
        }
        .modal-footer {
            padding: 0;
            .fixed-footer {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                padding: 16px 17.5px;
                gap: 16px;
                .custom-btn-cancel {
                    color: v.$primary-color;
                    background-color: v.$white;
                    border: 1px solid v.$primary-color;
                    border-radius: 6px;
                    font-size: 16px;
                    font-weight: 500;
                    &:hover {
                        background-color: v.$primary-color;
                        color: v.$white;
                    }
    
                    &:active {
                        background-color: darken(v.$primary-color, 10);
                    }
                }
                .custom-btn-assign {
                    box-shadow: unset;
                    border-radius: 6px;
                    font-size: 16px;
                    font-weight: 500;
                    &:hover {
                    background-color: darken(v.$primary-color, 5);
                    }
                    &:active {
                    background-color: darken(v.$primary-color, 10);
                    }
                    &:disabled {
                        cursor: default;
                        opacity: 0.5;
                        pointer-events: none;
                        background-color: v.$grey-300;
                    }
            
                }
            }
        }
    }
}
