.bold {
  font-weight: 700;
}

.dark-grey {
  color: v.$production-text-color;
}

.orderWrapper {
  padding: 5px;
  margin-top: 10px;
  overflow-y: scroll;

  .orderCard {
    margin: 10px;
    margin-top: 0;
    .card-body {
      cursor: pointer;
      .card-body-content {
        cursor: pointer;
        color: v.$card-body-text-color;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .order-list {
          display: flex;
        }

        .cart-list {
          display: flex;
          justify-content: left;
          align-items: center;
          margin-bottom: 15px;
          padding-top: 30px;
          .cart-item-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
          h6 {
            text-decoration: underline;
            font-weight: 500;
            cursor: pointer;
            font-size: 16px;
            @extend .dark-grey;
            span {
              font-weight: 700;
            }
          }
         
          .product-img {
            width: 120px;
            margin-right: 20px;
            border-radius: 6px;
          }

          .personalized-msg{
            color: v.$black;
            span{
              font-weight: bold;
            }
          }
          
          .gift-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 35px;
            .plus-wrap{
              font-size: 24px;
              font-weight: bold;
              color: v.$black;
              margin-right: 15px;
            }
            .gift-wrap-txt {
              font-size: 16px;
              color: v.$black;
              text-decoration: underline;
              span{
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .orderHeader {
    display: flex;
    align-items: center;
    border-bottom: 1px v.$order-header-color solid;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
  }
}

.assign-florist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: v.$production-text-color;
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
  position: relative;
  &:first-child {
    padding-inline-start: 3px;
  }
  &:last-child {
    padding-inline-end: 19px;
  }
  h6 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    line-height: 1.2;
    font-size: 16px;
    @extend .dark-grey;
    &.order-card-header {
      text-align: center;
    }
    &.order-card-desc {
      @extend .bold;
      min-height: 19px;
      text-align: center;
    }
  }
  .product-img {
    width: 120px;
    margin-right: 20px;
  }
  .sku {
    margin-left: 10px;
  }
  .product-name {
    margin-left: 10px;
  }
  .star-icon {
    fill: v.$star-icon-color;
    font-size: 22px;
    margin-left: 18px;
    width: 20px;
  }
  .prep-status {
    text-align: center;
  }
  .no-orders-title {
    text-align: center;
  }
  .order-priority {
    font-size: 23px;
    color: v.$red;
  }
}

.custom-btn-status {
  align-self: flex-start;
  height: unset;
  width: unset;
  margin-top: unset;
  padding: 12px 15px;
  border-radius: 6px;
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

.hide {
  display: none;
}

.statusBtn {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex-basis: 0;
  flex-grow: 1;
  font-size: 14px;
  line-height: 21px;
  min-width: 213px;
  max-width: 100%;
  min-height: 1px;
  overflow-wrap: break-word;
  padding: 5px 8px 5px 5px;
  position: relative;
  user-select: none;
  .custom-button {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.prepType-btn {
  margin-top: 24px;
  padding: 5px 10px;
  border-radius: 100px;
  &.prev-prepared-btn {
    border-radius: 4px;
    background: v.$prev-bg-btn;
    p {
      color: v.$prev-txt-btn;
    }
  }
}

.tags-left-col {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header-disclaimer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
  border-bottom: 1px v.$order-header-color solid;
  &.order-details-disclaimer {
    border-bottom: none;
    border-top: 1px v.$order-header-color solid;
  }
  svg { 
    padding: 0 4px 4px 0;
  }
  p {
    color: v.$warning-color;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
}