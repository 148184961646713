.fav-flowers-table {
  width: 100%;
  text-align: center;
  padding: 13px 16px;
  box-sizing: border-box;
  .fav-flowers-title {
    text-align: left;
    color: v.$note-red-color;
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 15px;
    text-decoration: underline;
  }
  .table {
    border: 0;
    width: 100%;
    th {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      color: v.$card-body-text-color;
      width: calc(100%/3);
    }
    td {
      font-size: 14px;
      line-height: 21px;
      color: v.$card-body-text-color;
      width: calc(100%/3);
      .fav-flower-img {
        width: 70px;
      }
    }
  }
}