.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(v.$black, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;

    .loading-spinner {
      display: inline-block;
      width: 25px;
      height: 25px;
      border: 3px solid v.$white;
      border-radius: 50%;
      border-top-color: v.$secondary-color;
      animation: spin 1s ease-in-out infinite;
    }

    p {
      padding-left: 10px;
      margin: 0;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
