.login-wrapper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 185px 30px 0;
  box-sizing: border-box;

  .login-title {
    font-size: 26px;
    line-height: 30px;
    color: v.$title-color;
    text-align: center;
    margin-bottom: 15px;
  }

  .login-form {
    padding-left: 6px;
    padding-right: 6px;
  }

	.custom-btn-login-microsoft {
		background-color: v.$ms-btn-bg;
		color: v.$black;
    margin-top: 0px;

		&:hover {
			background-color: v.$primary-color;
			color: v.$white;
		}
	}

  .or-text{
    margin-top: 21px;
    margin-bottom: 21px;
    width: 100%;
    text-align: center;
  }

	.ms-icon {
		width: 20px;
		margin-right: 10px;
	}
}
