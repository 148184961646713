// Design Colors
$primary-color: #0c697e;
$secondary-color: #3dc2ff;
$success-color: #2dd36f;
$danger-color: #eb445a;
$input-border-color: #2aa5c1;
$title-color: #222b45;
$ms-btn-bg: #fdfdfd;
$select-bg: #efefef;
$select-text-color: #495057;
$background-color: #dadada;
$search-icon-color: #666666;
$card-header-text-color: #4A5153;
$card-body-text-color: #737373;
$florist-btn-color: #f4f5f8;
$hf-btn-color: #EBFFE4;
$order-header-color: #d3d3d3;
$star-icon-color: #daa520;
$note-red-color: #dc143c;
$tab-background: #f8f8f8;
$production-text-color: #001115;
$warning-color: #F4AA24;
$notification-bg-color: #EDF7FA;
$light-gray-text: #7F7F7F;
$prev-bg-btn: #DDE2FC;
$prev-txt-btn: #5570F1;

// Basic Colors
$black: #000;
$black-300: #333333;
$white: #fff;
$red: #fd0012;
$grey-800: #DEDEDE;
$grey-700: #C8C8C8;
$grey-600: #E0E0E0;
$grey-500: #F9F9F9;
$grey-400: #B0B0B0;
$grey-300: #D8D8D8;
$grey-200: #e9ecef !default;
$grey-100: #c0c0c0;
$blue-200: #F4F5F7;
$blue-100: #FAFCFF;
$light-color: #f6f7fb;
$dark-color: #2a3142;
$light-semi-gray: #eff0f1;
$dark-blue: #022069;
$grey: #eeeeee;
$green: #89CA00;

// General variables
$theme-font-color: #1b252a;
$theme-body-font-color: #313131;
$theme-body-sub-title-color: #777777;

// Modal Variables

$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;

$modal-inner-padding: 1rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: 1.5 !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: 1px !default;
$modal-content-border-radius: 0.3rem !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.32 !default;
$modal-header-border-color: $grey-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 1rem !default;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-transition: transform 0.3s ease-out !default;

$image-shadow-color: #42474c;

//Card settings
$card-padding: 30px;
$card-margin-bottom: 30px;
$card-border-width: 0px;
$card-border-color: $light-color;
$card-border-radius: 6px;
$card-box-shadow: 1px 5px 24px 0 rgba($primary-color, 0.05);
$card-header-font-weight: 600;
$card-header-bg-color: $white;
$card-header-font-size: 18px;
$card-header-font-transform: uppercase;
$card-header-font-color: $theme-body-font-color;
$card-header-span-size: 12px;
$card-header-span-color: $theme-body-sub-title-color;
$card-body-bg-color: transparent;
$card-footer-bg-color: $white;

// Mixin
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$enable-transitions: true !default;
$transition-base: all 0.2s ease-in-out !default;

$enable-shadows: false !default;

$enable-rounded: true !default;

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}

@mixin border-top-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
}
