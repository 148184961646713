.tabs {
  display: flex;
  flex-direction: column;

  .labels-wrapper {
    display: flex;
    justify-content: space-evenly;
    height: 70px;
    color: v.$grey-100;
    background-color: v.$tab-background;
    user-select: none;

    .tab-label {
      text-align: center;
      width: 100%;
      padding: 15px 0;
      opacity: 0.7;
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      cursor: pointer;

      &.active-tab-label {
        position: relative;
        opacity: 1;
        color: v.$black;
        background-color: v.$white;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 4px;
          width: 100%;
          background-color: v.$primary-color;
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
        }
      }
    }
  }

  .tabs-content-wrapper {
    padding: 16px 14px;

    .tab-content {
      display: none;

      &.active-tab-content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}